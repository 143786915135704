.service-conatiner {
  margin-top: 6em;
}

.service-box-info {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.services {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
}

.service-card {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  padding: 0.4em 0.6em;
  background-color: #00000034;
}
.service-img {
  width: 4rem;
  filter: saturate(0%);
  /* filter: blur(5px); */
  /* filter: brightness(0.4); */
  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
  filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
  color: #000;
}

/* .service-card--7 .service-img,
  .service-card--4 .service-img,
  .service-card--6 .service-img {
    width: 4rem;
  } */

.service-card p {
  color: #000;
  font-weight: 600;
}

.service-card--2,
.service-card--4,
.service-card--6 {
  background-color: #fff;
}

.serviceImg {
  width: 100%;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  margin-top: 4em;

  font-size: 1rem;
  color: hsla(0, 0%, 6%, 0.801);
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
}

.serviceImg-2 {
  width: 100%;
}

/* .services-image-container {
    grid-row: 2;
  } */

@media (min-width: 800px) {
  .service-box-info {
    display: grid;
    grid-template-columns: 1fr 500px;
    /* grid-template-rows: 35rem; */
  }

  .service-box-img img {
    height: 35rem;
  }
  .col {
    width: 100%;
  }

  .serviceImg {
    height: 78vh;
  }

  .info-container {
    display: grid;
    grid-template-columns: 500px 1fr;
    gap: 1em;
  }

  .services-image-container {
    grid-column: 1 / span 1;
    grid-row: 1;
  }

  .services-info-container {
    grid-column: 2;
  }
}

@media (min-width: 1200px) {
  .service-conatiner {
    margin-top: 8em;
  }
  .serviceImg {
    height: 70vh;
  }
  .services {
    display: grid;
    grid-template-rows: 4rem 4rem 4rem 4rem 4rem 4rem 4rem;
    gap: 0.6em;
  }
}
