.overlay-sidebar {
  /* background-color: rgba(33, 33, 33, 0.397); */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.shop-now {
  /* background-color: #000; */
  color: #fff;

  margin-left: 1em;
  /* padding: 0.4em 1.4em; */
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: none;
  /* text-align: center; */
}

.section-sidebar {
  overflow: hidden;
  position: relative;
  color: #f6f6f6;
}

.sub-menu {
  /* display: flex; */
  /* flex-direction: column; */
  font-size: 0.8rem;
  /* color: #f6f6f6;
      text-decoration: none; */
}

.aside {
  position: fixed;
  top: 0;
  right: 0;
  width: 270px;
  height: 100%;
  /* background-color: rgb(50, 210, 210); */
  background-color: #1a2124a2;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
}

.sidebar-content {
  position: absolute;
  top: 8%;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin: 0 auto;
  width: 100%;
}

.sidebar-content a,
.sidebar-content .services-link {
  text-decoration: none;
  /* background-color: aliceblue; */
  margin: 0 0.9em;
  padding: 0.3em;
  color: #f6f6f6;
}

.sidebar-content a:hover {
  text-decoration: underline;
}

.close-btn {
  position: absolute;
  top: 3.8%;
  right: 18%;
  background-color: transparent;
  font-size: 1.8rem;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: inline-block;
  padding: 0.3em 0.36em;
  transition: 0.6s;
}

.close-btn .close {
  transform: translate(0, 10%);
}

.close-btn:hover {
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
}

.developed {
  position: absolute;
  bottom: 0%;
  text-align: center;
  width: 100%;
  text-transform: capitalize;
  font-size: 0.8rem;
  border-top: 1px solid rgba(177, 177, 177, 0.486);
  padding: 1em;
}

.link-sidebar {
  width: 250px;

  color: rgb(0, 0, 0);
}

.contact-us,
.policy,
.home {
  font-size: 0.9rem;
  /* padding: 0em 2em; */
  text-transform: capitalize;
  text-decoration: none;
}
.link-sidebar p {
  /* margin-bottom: 0.6em; */
  /* border: 1px solid #009fe317; */
}

.link-sidebar:hover {
  /* background-color: rgba(226, 223, 223, 0.548);
        background-color: #009fe317; */
  color: #00a0e3;
  border-radius: 4px;
}

.link-sidebar p {
  padding: 1.2em 2em;
  /* border: 1px solid rgba(148, 148, 148, 0.189); */
  border-radius: 4px;
}

/* .sidebar-content .active {
      background-color: #009fe317;
      color: #00a0e3;
      border-radius: 4px;
    } */

@media (min-width: 800px) {
  .section-sidebar {
    display: none;
  }
}
