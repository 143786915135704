.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
}

.center {
  padding: 0.7em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1128px;
  margin: 0 auto;
  width: 80%;
}

.shop-now-btn {
  background-color: #000;
  color: #fff;
  margin-left: 1em;
  padding: 0.4em 1.4em;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: none;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3d3d3d48;
}

.center-container {
  border-bottom: 1px solid rgb(190, 187, 187);
}

.icon-menu {
  color: #0e1318;
  font-size: 2rem;
  cursor: pointer;
  background: transparent;
  border: 0;

  /* padding: 0.3em; */
  transition: 0.6s;
}

.menu {
  border: 0;
  background: transparent;
  padding: 0.4em;
}

.menu:hover {
  background-color: #3d3d3d10;
  border-radius: 50%;
  /* padding: 0.3em; */
  cursor: pointer;
}

.icon-img {
  height: 7rem;
  padding: 0.4em 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.icon-img-new {
  height: 3.8rem;
  padding: 0.4em 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (min-width: 600px) {
  .icon-img-new {
    height: 4.5rem;
    padding: 0.4em 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.icon-img2 {
  height: 4rem;
  padding: 0.4em 0;
  /* -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); */

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.nav {
  display: none;
}

ul li a {
  cursor: pointer;
}

.active {
  color: #08a2e4;
}

@media (min-width: 700px) {
  .center {
    /* width: 76%; */
  }
}

@media (min-width: 800px) {
  .nav {
    visibility: visible;
    display: flex;
    align-items: center;

    height: auto;
    position: relative;
  }

  .nav__item {
    margin: 0 0 0 1.5em;
  }

  .nav-contact,
  .nav-home,
  .nav-policy {
    display: block;
    text-decoration: none;

    text-transform: capitalize;
    font-size: 0.9rem;
    align-items: center;
    padding: 0.3em;
    color: #1e252b;
    font-weight: 500;
    height: auto;
  }

  .nav-contact:hover,
  .nav-home:hover,
  .nav-policy:hover {
    /* text-decoration: underline 2px solid rgb(114, 114, 114); */
  }

  .menu {
    display: none;
  }

  .nav__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  .nav__list li {
    list-style: none;
  }

  .nav__list + .nav__list {
    margin-left: 1em;
  }

  .nav__list--primary .nav-item {
    margin: 0 0.3em;
    background-color: #a8a8a81a;
    border-radius: 50%;
    padding: 0.3em 0.5em;
  }

  .nav__list--primary .nav-item:last-child {
    margin-right: 0;
  }

  .active {
    border-bottom: 2px solid #08a3e4;
    color: "#08a3e4";

    transition: transform 0.3s ease-in-out;
  }

  .contact {
    text-decoration: none;
  }

  .color {
    color: #000;
  }

  .nav-link {
    position: relative;
  }

  /* .nav-link:after {
          bottom: 0px;
          content: "";
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          background-color: #08a2e4;
          opacity: 0.6;
          transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
          width: 0;
          border-radius: 9px;
        } */

  /* .nav-link:hover:after {
            width: 100%;
            opacity: 0.9;
          } */
  /* 
        .activelink:after {
          width: 100%;
          opacity: 0.9;
          transition: all 0.3s ease-in-out;
        } */

  .activelink {
    /* color: #3b3b3b; */
  }
}
/* 
      @media (min-width: 1200px) {
        .contact,
        .home,
        .policy {
          font-size: 0.9rem;
        }
      
        .nav__list--secondary {
          gap: 12px;
        }
      
        .nav__list--primary {
          gap: 5px;
        }
      
        .nav__list + .nav__list {
          margin-left: 1.4em;
        }
      
        .nav-contact,
        .nav-home,
        .nav-policy {
          padding: 0.5em;
        }
      }
      
      @media (min-width: 1400px) {
        .contact,
        .home,
        .policy {
          font-size: 1rem;
        }
      } */
.submenu-container {
  display: none;
}

@media (min-width: 800px) {
  .sub-menu {
    display: flex;
    /* flex-direction: column; */
    font-size: 0.8rem;
    color: #f6f6f6;
    text-decoration: none;
  }

  .sub-menu:hover {
    text-decoration: underline;
  }

  .service-nav-link {
    position: relative;
  }

  .submenu-container {
    background-color: #fdfdfd;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 2em 1em;
    position: fixed;

    top: 10%;
    right: 5%;
    border: 1px solid rgba(145, 145, 145, 0.071);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    display: grid;
    grid-template-columns: repeat(5, 8rem);
    /* grid-template-rows: repeat(3, 4rem); */
    gap: 0.6em;
    justify-content: center;
    /* align-items: center; */
    font-weight: 500;
    border-radius: 9px;
    z-index: 999;
  }

  .submenu-flex {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
  }

  .sub-menu {
    color: #000;
    font-size: 0.9rem;
    padding: 0.4em 1em;
  }

  .sub-menu:hover {
    color: #08a3e4;
    text-decoration: none;
    background-color: #08a2e413;
    border-radius: 8px;
  }
}

@media (min-width: 1200px) {
  .submenu-container {
    background-color: #fdfdfd;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 4em 0.6em;
    position: fixed;
    top: 12%;
    right: 5%;
    width: 90%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    display: grid;
    /* justify-content: center;
      align-items: center; */
    grid-template-columns: repeat(5, 12rem);
    /* grid-template-rows: repeat(2, 6rem); */
    gap: 1.4em;
    /* border-radius: 9px; */
    z-index: 999;
  }

  .sub-menu {
    color: #000000ea;
    font-size: 1rem;
  }
}
